export const addSalida = async (TOKEN, USERID, salida) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'client/api/v1/salida/create',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // prettier-ignore
          'Authorization': 'Bearer ' + TOKEN,
        },
        body: JSON.stringify({
          fecha: new Date(),
          salidaId: +salida.salidaId,
          registerId: salida.salidaId + '.' + salida.id,
          folioMaquila: +salida.folioMaquila,
          numeroIco: salida.numeroIco,
          estado: salida.estado,
          region: salida.region,
          municipio: salida.municipio,
          comunidad: salida.comunidad,
          productor: salida.productor,
          tipo: salida.tipo,
          caracteristica: salida.caracteristica,
          cosecha: salida.cosecha,
          direccion: salida.direccion,
          ciudad: salida.ciudad,
          chofer: salida.chofer,
          placas: salida.placas,
          marca: salida.marca,
          unidad: salida.unidad,
          cumple: salida.cumple,
          sacosTotal: salida.sacosTotal.toString(),
          kilosBrutoTotal: salida.kilosBrutoTotal.toString(),
          kilosNetoTotal: salida.kilosNetoTotal.toString(),
          precioLibra: salida.precioLibra.toString(),
          observaciones: salida.observaciones,
          createdAt: new Date(),
          createdBy: USERID,
        }),
      },
    );
    return response;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const getAllSalida = async (TOKEN) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'client/api/v1/salida/list',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // prettier-ignore
          'Authorization': 'Bearer ' + TOKEN,
        },
        body: JSON.stringify({
          options: {
            paginate: 100,
            sort: { createdAt: 1 },
          },
          isCountOnly: false,
        }),
      },
    );
    return response;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const getLatestId = async (TOKEN) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'client/api/v1/salida/list',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // prettier-ignore
          'Authorization': 'Bearer ' + TOKEN,
        },
        body: JSON.stringify({
          options: {
            paginate: 1,
            sort: { id: -1 },
            select: ['salidaId'],
          },
          isCountOnly: false,
        }),
      },
    );
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }

    const responseData = await response.json();
    const latestId =
      responseData.data?.data.length > 0
        ? responseData.data?.data[0].salidaId
        : 0;
    return latestId;
  } catch (error) {
    console.error('Error:', error);
  }
};
