export const addRendimiento = async (TOKEN, USERID, rendimiento) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'client/api/v1/rendimiento/create',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // prettier-ignore
          'Authorization': 'Bearer ' + TOKEN,
        },
        body: JSON.stringify({
          fecha: new Date(),
          folio: +rendimiento.folio,
          entradaId: +rendimiento.entradaId,
          claveDescarga: rendimiento.claveDescarga,
          estado: rendimiento.estado,
          region: rendimiento.region,
          municipio: rendimiento.municipio,
          comunidad: rendimiento.comunidad,
          productor: rendimiento.productor,
          tipo: rendimiento.tipo,
          caracteristica: rendimiento.caracteristica,
          humedad: rendimiento.humedad.toString(),
          actividadAgua: rendimiento.actividadAgua.toString(),
          muestra: rendimiento.muestra.toString(),
          verdeExportable: rendimiento.verdeExportable.toString(),
          inferiores: rendimiento.inferiores.toString(),
          verdeTotal: rendimiento.verdeTotal.toString(),
          rendimientoExportable: rendimiento.rendimientoExportable.toString(),
          rendimientoInferior: rendimiento.rendimientoInferior.toString(),
          rendimientoTotal: rendimiento.rendimientoTotal.toString(),
          observaciones: rendimiento.observaciones,
          createdAt: new Date(),
          createdBy: USERID,
        }),
      },
    );
    return response;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const getAllRendimiento = async (TOKEN) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'client/api/v1/rendimiento/list',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // prettier-ignore
          'Authorization': 'Bearer ' + TOKEN,
        },
        body: JSON.stringify({
          options: {
            paginate: 100,
            sort: { createdAt: 1 },
          },
          isCountOnly: false,
        }),
      },
    );
    return response;
  } catch (error) {
    console.error('Error:', error);
  }
};
