export const addOrigen = async (TOKEN, USERID, origen) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'client/api/v1/origen/create',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // prettier-ignore
          'Authorization': 'Bearer ' + TOKEN,
        },
        body: JSON.stringify({
          estado: origen.estado,
          region: origen.region,
          municipio: origen.municipio,
          comunidad: origen.comunidad,
          createdAt: new Date(),
          createdBy: USERID,
        }),
      },
    );
    return response;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const addProductor = async (TOKEN, USERID, productor) => {
  const now = new Date();
  const dateString = now.toLocaleString();

  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'client/api/v1/productor/create',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // prettier-ignore
          'Authorization': 'Bearer ' + TOKEN,
        },
        body: JSON.stringify({
          productor: productor.productor,
          representante: productor.representante,
          estado: productor.estado,
          region: productor.region,
          municipio: productor.municipio,
          comunidad: productor.comunidad,
          programaFertilizante: productor.programaFertilizante,
          clavePNC: productor.clavePNC,
          fechaRegistro: dateString,
          createdAt: now,
          createdBy: USERID,
        }),
      },
    );
    return response;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const getAllOrigen = async (TOKEN) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'client/api/v1/origen/list',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // prettier-ignore
          'Authorization': 'Bearer ' + TOKEN,
        },
        body: JSON.stringify({
          options: {
            paginate: 100,
            sort: { createdAt: 1 },
          },
          isCountOnly: false,
        }),
      },
    );
    return response;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const getAllProductor = async (TOKEN) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'client/api/v1/productor/list',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // prettier-ignore
          'Authorization': 'Bearer ' + TOKEN,
        },
        body: JSON.stringify({
          options: {
            paginate: 100,
            sort: { createdAt: 1 },
          },
          isCountOnly: false,
        }),
      },
    );
    return response;
  } catch (error) {
    console.error('Error:', error);
  }
};
