const sanitizeEntrada = (entrada, USERID) => {
  const toNumberWithTwoDecimals = (value) => parseFloat(value || 0).toFixed(2);

  return {
    entradaId: entrada.entradaId || '',
    fecha: new Date(),
    registerId: entrada.registerId || `${entrada.entradaId}.${entrada.id || 0}`,
    estado: entrada.estado || '',
    region: entrada.region || '',
    municipio: entrada.municipio || '',
    comunidad: entrada.comunidad || '',
    productor: entrada.productor || '',
    cosecha: entrada.cosecha || '',
    tipo: entrada.tipo || '',
    chofer: entrada.chofer || '',
    placas: entrada.placas || '',
    marca: entrada.marca || '',
    unidad: entrada.unidad || '',
    cumple: entrada.cumple || '',
    caracteristica: entrada.caracteristica || '',
    ticketBascula: entrada.ticketBascula || '',
    pesoConCarga: toNumberWithTwoDecimals(entrada.pesoConCarga),
    pesoSinCarga: toNumberWithTwoDecimals(entrada.pesoSinCarga),
    yutes: toNumberWithTwoDecimals(entrada.yutes),
    lonas: toNumberWithTwoDecimals(entrada.lonas),
    cajas: toNumberWithTwoDecimals(entrada.cajas),
    tara: toNumberWithTwoDecimals(entrada.tara),
    pesoBruto: toNumberWithTwoDecimals(entrada.pesoBruto),
    pesoNeto: toNumberWithTwoDecimals(entrada.pesoNeto),
    pesoPorSaco: toNumberWithTwoDecimals(entrada.pesoPorSaco),
    humedad: toNumberWithTwoDecimals(entrada.humedad),
    descuentoHumedad: toNumberWithTwoDecimals(entrada.descuentoHumedad),
    descuentoHumedadPeso: toNumberWithTwoDecimals(entrada.descuentoHumedadPeso),
    precioPrevio: toNumberWithTwoDecimals(entrada.precioPrevio),
    precioKg: toNumberWithTwoDecimals(entrada.precioKg),
    precioTotal: toNumberWithTwoDecimals(entrada.precioTotal),
    observaciones: entrada.observaciones || '',
    createdAt: new Date(),
    createdBy: entrada.createdBy || USERID,
  };
};

export const addEntrada = async (TOKEN, USERID, entrada) => {
  try {
    const sanitizedEntrada = sanitizeEntrada(entrada, USERID);
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'client/api/v1/entrada/create',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // prettier-ignore
          'Authorization': 'Bearer ' + TOKEN,
        },
        body: JSON.stringify(sanitizedEntrada),
      },
    );
    return response;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const getAllEntradas = async (TOKEN) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'client/api/v1/entrada/list',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // prettier-ignore
          'Authorization': 'Bearer ' + TOKEN,
        },
        body: JSON.stringify({
          options: {
            paginate: 100,
            sort: { createdAt: 1 },
          },
          isCountOnly: false,
        }),
      },
    );
    return response;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const getLatestId = async (TOKEN) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'client/api/v1/entrada/list',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // prettier-ignore
          'Authorization': 'Bearer ' + TOKEN,
        },
        body: JSON.stringify({
          options: {
            paginate: 1,
            sort: { id: -1 },
            select: ['entradaId'],
          },
          isCountOnly: false,
        }),
      },
    );
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }

    const responseData = await response.json();
    const latestId =
      responseData.data?.data.length > 0
        ? responseData.data?.data[0].entradaId
        : 0;
    return latestId;
  } catch (error) {
    console.error('Error:', error);
  }
};
