export const addSecado = async (TOKEN, USERID, secado) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'client/api/v1/secado/create',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // prettier-ignore
          'Authorization': 'Bearer ' + TOKEN,
        },
        body: JSON.stringify({
          fecha: new Date(),
          entradaId: +secado.entradaId,
          folio: secado.folio.toString(),
          claveDescarga: secado.claveDescarga,
          excluidos: secado.excluidos?.length ? secado.excluidos.join(',') : '',
          estado: secado.estado,
          region: secado.region,
          comunidad: secado.comunidad,
          municipio: secado.municipio,
          productor: secado.productor,
          tipo: secado.tipo,
          caracteristica: secado.caracteristica,
          pergamino: secado.pergamino.toString(),
          pergaminera: secado.pergaminera.toString(),
          merma: secado.merma.toString(),
          pesoTotal: secado.pesoTotal.toString(),
          pergaminoTotal: secado.pergaminoTotal.toString(),
          pergamineraTotal: secado.pergamineraTotal.toString(),
          mermaTotal: secado.mermaTotal.toString(),
          foliosTotal: secado.foliosTotal.toString(),
          estatus: secado.estatus,
          observaciones: secado.observaciones,
          createdAt: new Date(),
          createdBy: USERID,
        }),
      },
    );
    return response;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const getAllSecado = async (TOKEN) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'client/api/v1/secado/list',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // prettier-ignore
          'Authorization': 'Bearer ' + TOKEN,
        },
        body: JSON.stringify({
          options: {
            paginate: 100,
            sort: { createdAt: 1 },
          },
          isCountOnly: false,
        }),
      },
    );
    return response;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const getLatestId = async (TOKEN) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'client/api/v1/secado/list',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // prettier-ignore
          'Authorization': 'Bearer ' + TOKEN,
        },
        body: JSON.stringify({
          options: {
            paginate: 1,
            sort: { id: -1 },
            select: ['id'],
          },
          isCountOnly: false,
        }),
      },
    );
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }

    const responseData = await response.json();
    const latestId =
      responseData.data?.data.length > 0 ? responseData.data?.data[0].id : 1;
    return latestId;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const asignClient = async (TOKEN, ID, client) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL +
        'client/api/v1/secado/partial-update/' +
        ID,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          // prettier-ignore
          'Authorization': 'Bearer ' + TOKEN,
        },
        body: JSON.stringify({
          estatus: 'oferta',
          cliente: client,
        }),
      },
    );
    if (!response.ok) {
      throw new Error('Failed to update model');
    }

    return response;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const changeStatus = async (TOKEN, ID, newStatus) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL +
        'client/api/v1/secado/partial-update/' +
        ID,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          // prettier-ignore
          'Authorization': 'Bearer ' + TOKEN,
        },
        body: JSON.stringify({
          estatus: newStatus,
        }),
      },
    );
    if (!response.ok) {
      throw new Error('Failed to update model');
    }

    return response;
  } catch (error) {
    console.error('Error:', error);
  }
};
