import React, { useEffect, useState } from 'react';

// Chakra imports
import {
  Flex,
  Text,
  useColorModeValue,
  Select,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  SimpleGrid,
  useDisclosure,
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card.js';

// Assets
import balanceImg from 'assets/img/dashboards/balanceImg.png';
import { AiOutlineSchedule } from 'react-icons/ai';
import { extractNumericValue } from 'utils/format';

export default function Resultado({
  rendimiento,
  isSubmitted,
  handleFocus,
  handleChange,
  origen,
}) {
  const balanceBg = useColorModeValue('brand.900', '#1B254B');
  const textColor = useColorModeValue('secondaryGray.500', 'white');

  const handleOrigen = (data, attribute, conditionName, conditionValue) => {
    const uniqueArray = [];
    const uniqueSet = new Set();
    if (data?.length > 0) {
      data.forEach((obj) => {
        const value = obj[attribute];

        if (value !== null && value && !uniqueSet.has(value)) {
          if (
            conditionName &&
            conditionValue &&
            conditionName !== conditionValue.toLowerCase() &&
            conditionValue !== 'No especificado'
          ) {
            if (obj[conditionName] === conditionValue) {
              uniqueSet.add(value);
              uniqueArray.push(value);
            }
          } else {
            uniqueSet.add(value);
            uniqueArray.push(value);
          }
        }
      });
      return ['No especificado', ...uniqueArray];
    } else {
      return [];
    }
  };

  const [disabled, setDisabled] = useState(false);
  const [estado, setEstado] = useState([]);
  const [region, setRegion] = useState([]);
  const [municipio, setMunicipio] = useState([]);
  const [comunidad, setComunidad] = useState([]);

  useEffect(() => {
    setEstado(handleOrigen(origen, 'estado'));
    setRegion(handleOrigen(origen, 'region'));
    setMunicipio(handleOrigen(origen, 'municipio'));
    setComunidad(handleOrigen(origen, 'comunidad'));
  }, []);

  return (
    <Card direction="column" w="100%">
      <Flex
        justify="space-between"
        p="20px"
        mb="20px"
        borderRadius="16px"
        bgColor={balanceBg}
        bgImage={balanceImg}
        bgPosition="right"
        bgSize="cover"
      >
        <Flex align="center" justify="space-between" w="100%">
          <Flex alignItems="center" gap="15px" flexDirection="row" me="14px">
            <AiOutlineSchedule color="white" size={'20px'} />
            <Text
              color="white"
              fontSize="20px"
              fontWeight="700"
              lineHeight="100%"
            >
              Resultados del proceso de rendimiento
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <FormControl>
        <SimpleGrid
          columns={{ base: '1', md: '3', lg: '3' }}
          gap={{ sm: '10px', md: '26px' }}
          mb={'1rem'}
        >
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Humedad
            </FormLabel>
            <InputGroup>
              <Input
                borderColor={
                  isSubmitted && !rendimiento.humedad ? 'red.500' : null
                }
                value={rendimiento.humedad}
                onChange={(e) =>
                  handleChange(
                    'humedad',
                    extractNumericValue(e.target.value.trim()),
                  )
                }
                onFocus={handleFocus}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Humedad"
                variant="auth"
                size="lg"
              />
              <InputRightAddon color={textColor}>%</InputRightAddon>
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Actividad de agua
            </FormLabel>
            <InputGroup>
              <Input
                borderColor={
                  isSubmitted && !rendimiento.actividadAgua ? 'red.500' : null
                }
                value={rendimiento.actividadAgua}
                onChange={(e) =>
                  handleChange(
                    'actividadAgua',
                    extractNumericValue(e.target.value.trim()),
                  )
                }
                onFocus={handleFocus}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Actividad de agua"
                variant="auth"
                size="lg"
              />
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Muestra pergamino en gramos
            </FormLabel>
            <InputGroup>
              <Input
                borderColor={
                  isSubmitted && !rendimiento.muestra ? 'red.500' : null
                }
                value={rendimiento.muestra}
                onChange={(e) =>
                  handleChange(
                    'muestra',
                    extractNumericValue(e.target.value.trim()),
                  )
                }
                onFocus={handleFocus}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Muestra pergamino"
                variant="auth"
                size="lg"
              />
              <InputRightAddon color={textColor}>GR</InputRightAddon>
            </InputGroup>
          </Flex>
        </SimpleGrid>
        <SimpleGrid
          columns={{ base: '1', md: '2', lg: '2' }}
          gap={{ sm: '10px', md: '26px' }}
          mb={'1rem'}
        >
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Café verde exportable
            </FormLabel>
            <InputGroup>
              <Input
                borderColor={
                  isSubmitted && !rendimiento.verdeExportable ? 'red.500' : null
                }
                value={rendimiento.verdeExportable}
                onChange={(e) => {
                  const value = extractNumericValue(e.target.value.trim());
                  handleChange('verdeExportable', value);
                  if (+rendimiento.muestra) {
                    handleChange(
                      'rendimientoExportable',
                      ((value / +rendimiento.muestra) * 100).toFixed(2),
                    );
                  }
                }}
                onFocus={handleFocus}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Café verde exportable"
                variant="auth"
                size="lg"
              />
              <InputRightAddon color={textColor}>GR</InputRightAddon>
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Rendimiento exportable
            </FormLabel>
            <InputGroup>
              <Input
                borderColor={
                  isSubmitted && !rendimiento.rendimientoExportable
                    ? 'red.500'
                    : null
                }
                value={rendimiento.rendimientoExportable}
                onChange={(e) =>
                  handleChange(
                    'rendimientoExportable',
                    extractNumericValue(e.target.value.trim()),
                  )
                }
                onFocus={handleFocus}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Rendimiento exportable"
                variant="auth"
                size="lg"
              />
              <InputRightAddon color={textColor}>%</InputRightAddon>
            </InputGroup>
          </Flex>
        </SimpleGrid>
        <SimpleGrid
          columns={{ base: '1', md: '2', lg: '2' }}
          gap={{ sm: '10px', md: '26px' }}
          mb={'1rem'}
        >
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Inferiores
            </FormLabel>
            <InputGroup>
              <Input
                borderColor={
                  isSubmitted && !rendimiento.inferiores ? 'red.500' : null
                }
                value={rendimiento.inferiores}
                onChange={(e) => {
                  const value = extractNumericValue(e.target.value.trim());
                  handleChange('inferiores', value);
                  if (+rendimiento.muestra) {
                    handleChange(
                      'rendimientoInferior',
                      ((value / +rendimiento.muestra) * 100).toFixed(2),
                    );
                  }
                }}
                onFocus={handleFocus}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Inferiores"
                variant="auth"
                size="lg"
              />
              <InputRightAddon color={textColor}>GR</InputRightAddon>
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Rendimiento inferior
            </FormLabel>
            <InputGroup>
              <Input
                borderColor={
                  isSubmitted && !rendimiento.rendimientoInferior
                    ? 'red.500'
                    : null
                }
                value={rendimiento.rendimientoInferior}
                onChange={(e) =>
                  handleChange(
                    'rendimientoInferior',
                    extractNumericValue(e.target.value.trim()),
                  )
                }
                onFocus={handleFocus}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Rendimiento inferior"
                variant="auth"
                size="lg"
              />
              <InputRightAddon color={textColor}>%</InputRightAddon>
            </InputGroup>
          </Flex>
        </SimpleGrid>
        <SimpleGrid
          columns={{ base: '1', md: '2', lg: '2' }}
          gap={{ sm: '10px', md: '26px' }}
          mb={'1rem'}
        >
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Café verde total
            </FormLabel>
            <InputGroup>
              <Input
                borderColor={
                  isSubmitted && !rendimiento.verdeTotal ? 'red.500' : null
                }
                value={rendimiento.verdeTotal}
                onChange={(e) => {
                  const value = extractNumericValue(e.target.value.trim());
                  handleChange('verdeTotal', value);
                  if (+rendimiento.muestra) {
                    handleChange(
                      'rendimientoTotal',
                      ((value / +rendimiento.muestra) * 100).toFixed(2),
                    );
                  }
                }}
                onFocus={handleFocus}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Café verde total"
                variant="auth"
                size="lg"
              />
              <InputRightAddon color={textColor}>GR</InputRightAddon>
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Rendimiento total
            </FormLabel>
            <InputGroup>
              <Input
                borderColor={
                  isSubmitted && !rendimiento.rendimientoTotal
                    ? 'red.500'
                    : null
                }
                value={rendimiento.rendimientoTotal}
                onChange={(e) =>
                  handleChange(
                    'rendimientoTotal',
                    extractNumericValue(e.target.value.trim()),
                  )
                }
                onFocus={handleFocus}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Rendimiento total"
                variant="auth"
                size="lg"
              />
              <InputRightAddon color={textColor}>%</InputRightAddon>
            </InputGroup>
          </Flex>
        </SimpleGrid>
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Observaciones
          </FormLabel>
          <InputGroup>
            <Input
              value={rendimiento.observaciones}
              onChange={(e) => handleChange('observaciones', e.target.value)}
              onFocus={handleFocus}
              borderRadius="6px"
              height="40px"
              fontSize="md"
              placeholder="Observaciones"
              variant="auth"
              size="lg"
            />
          </InputGroup>
        </Flex>
      </FormControl>
    </Card>
  );
}
